import React from "react"
import styled from "styled-components"
import Seo from "src/components/SEO"
import SectionFullBleed from "src/components/Section/SectionFullBleed"

const SubscribedPage = () => {
  return (
    <Section>
      <Seo title="Thank you!" />
      <SectionFullBleed
        primaryTitle="Thank you!"
        title="Your email address has been saved."
        image="/images/hero/hen.jpg"
      />
    </Section>
  )
}

const Section = styled.div``

export default SubscribedPage
